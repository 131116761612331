import {
  CLEAR_USER_COLLAB_DATA,
  SET_USER_COLLAB_DATA,
} from "../actions/userActions";

const initialState = {
  newtuber: null,
  collab: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_COLLAB_DATA:
      return {
        ...state,
        collab: action.payload,
      };
    case CLEAR_USER_COLLAB_DATA:
      return {
        ...state,
        collab: null,
      };
    default:
      return state;
  }
};

export default userReducer;
