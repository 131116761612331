import Locate from "leaflet.locatecontrol";
import React from "react";
import { useMap } from "react-leaflet";

class LocateControl extends React.Component {
  componentDidMount() {
    const { options, startDirectly, map } = this.props;

    const lc = new Locate(options);
    lc.addTo(map);

    if (startDirectly) {
      // request location update and set location
      lc.start();
    }
  }

  render() {
    return null;
  }
}

function withMap(Component) {
  return function WrappedComponent(props) {
    const map = useMap();
    return <Component {...props} map={map} />;
  };
}

export default withMap(LocateControl);
