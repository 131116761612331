import React from "react";
import { Button, Card, Container } from "react-bootstrap";
import "./OverlayCardContainer.scss";

function OverlayCardContainer(props) {
  return (
    <Container fluid className={"overlay-container " + props.className}>
      <Button className="close-button" onClick={props.onCloseButtonClicked}>
        X
      </Button>
      <Card className="overlay-card p-4">
        <Card.Body>{props.children}</Card.Body>
      </Card>
    </Container>
  );
}

export default OverlayCardContainer;
