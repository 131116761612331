import { LatLng } from "leaflet";
import React, { useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { InputTags } from "react-bootstrap-tagsinput";
import "react-bootstrap-tagsinput/dist/index.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { useSelector } from "react-redux";
import { setYoutuber } from "../../api/colab-api";
import { auth } from "../../api/firebase";
import LocationPickerMap from "../../components/LocationPickerMap";
import SubscriberCount from "../../components/SubscriberCount";
import "./CreateCollabNote.scss";

function CreateCollabNote({ onCancel, onSuccess, onError }) {
  const [user] = useAuthState(auth);
  const [genres, setGenres] = useState([]);
  const [message, setMessage] = useState("");
  const [location, setLocation] = useState(null);
  const userData = useSelector((state) => state.user);
  const channelData = useSelector((state) => state.youtube.userData);

  const onSave = () => {
    if (!user || !channelData || !channelData.id) {
      alert("There was an error. Sign out and in again.");
      console.error("onSave error: invalid user or channelData");
      return;
    }

    const data = {
      uid: user.uid,
      channelId: channelData.id,
      message: message,
      location: location,
      genres: genres,
    };

    setYoutuber(user, data)
      .then(function (response) {
        onSuccess(response.data);
      })
      .catch(function (error) {
        onError(error);
      });
  };

  return (
    <Card style={{ width: "auto" }}>
      <Card.Body className="m-3">
        <Container>
          <Row>
            <Col>
              {channelData?.snippet?.thumbnails?.default?.url ? (
                <Card.Img
                  variant="top"
                  style={{ width: "6rem" }}
                  src={channelData?.snippet?.thumbnails?.default?.url}
                />
              ) : (
                <></>
              )}
            </Col>
            <Col>
              <Row>
                <Card.Text>{channelData?.snippet?.title}</Card.Text>
              </Row>
              <Row>
                <SubscriberCount
                  count={channelData?.statistics?.subscriberCount}
                ></SubscriberCount>
              </Row>
            </Col>
          </Row>
        </Container>

        <Form className="mt-3">
          <Form.Group controlId="formMessage">
            <Form.Label>Your message</Form.Label>
            <Form.Control
              style={{ background: "white" }}
              required={true}
              as="textarea"
              type="text"
              rows="5"
              defaultValue={userData?.collab?.message}
              onChange={(message) => {
                setMessage(message.target.value);
              }}
              placeholder="Hey there, I’m doing travel vlogs and are
              always looking for other creators to
              collab with, hit me up if you  are in
              the area an have 100k+ subs"
            />

            <Form.Label className="mt-3">Your genres</Form.Label>
            <div className="input-group">
              <InputTags
                elementClassName="input-tag"
                values={userData?.collab?.genres}
                onTags={(value) => setGenres(value.values)}
              />
              <button
                className="btn btn-outline-primary"
                type="button"
                data-testid="button-clearAll"
                onClick={() => {
                  setGenres([]);
                }}
              >
                Delete all
              </button>
            </div>
          </Form.Group>

          <Form.Group className="mt-3" controlId="formLocatin">
            <Form.Label>Your location</Form.Label>
            <LocationPickerMap
              initialLocation={
                userData?.collab?.location &&
                userData?.collab?.location?._lat &&
                userData?.collab?.location?._long
                  ? new LatLng(
                      Number(userData?.collab?.location?._lat),
                      Number(userData?.collab?.location?._long)
                    )
                  : null
              }
              onLocationChanged={(location) => {
                setLocation(location);
              }}
            ></LocationPickerMap>
          </Form.Group>
        </Form>
        <Container fluid className="mt-3">
          <Row className="justify-content-evenly">
            <Col xs="auto">
              <Button variant="primary" onClick={onSave}>
                Save
              </Button>
            </Col>
            <Col xs="auto">
              <Button variant="secondary" onClick={onCancel}>
                Cancel
              </Button>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}

export default CreateCollabNote;
