export const SET_USER_YOUTUBE_DATA = "SET_USER_YOUTUBE_DATA";
export const CLEAR_USER_YOUTUBE_DATA = "CLEAR_USER_YOUTUBE_DATA";

export function setUserYoutubeDataAction(data) {
  return { type: SET_USER_YOUTUBE_DATA, payload: data };
}

export function clearUserYoutubeDataAction() {
  return { type: CLEAR_USER_YOUTUBE_DATA, payload: null };
}
