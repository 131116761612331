import {
  CLEAR_USER_YOUTUBE_DATA,
  SET_USER_YOUTUBE_DATA,
} from "../actions/youtubeActions";

const initialState = {
  userData: null,
};

const youtubeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_YOUTUBE_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case CLEAR_USER_YOUTUBE_DATA:
      return {
        ...state,
        userData: null,
      };
    default:
      return state;
  }
};

export default youtubeReducer;
