import React from "react";
import { Col, Row } from "react-bootstrap";
import "react-confirm-alert/src/react-confirm-alert.css";
import { BsArrowRight } from "react-icons/bs";

function NavItem({ title, href, onClick }) {
  return (
    <Row className="mb-2">
      <Col xs={10}>
        <a
          onClick={onClick}
          href={href}
          style={{ color: "#212529", textDecoration: "none" }}
        >
          <div>{title}</div>
        </a>
      </Col>
      <Col xs={2}>
        <a onClick={onClick} href={href}>
          <BsArrowRight></BsArrowRight>
        </a>
      </Col>
    </Row>
  );
}

export default NavItem;
