import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Page from "./Page";

function ThePerfectCollab() {
  return (
    <Page>
      <Container fluid>
        <Row className="justify-content-center" style={{ marginTop: "10vh" }}>
          <Col xs="auto">
            <h1 style={{ color: "white" }}>Coming soon...</h1>
          </Col>
        </Row>
      </Container>
    </Page>
  );
}

export default ThePerfectCollab;
