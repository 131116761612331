import React from "react";
import { Card, Container, Row } from "react-bootstrap";
import Page from "./Page";

function PrivacyPolicy() {
  return (
    <Page>
      <Container fluid>
        <Row
          className="justify-content-center"
          style={{ marginTop: "10vh", marginLeft: "5vw", marginRight: "5vw" }}
        >
          <Card className="mb-3">
            <Card.Body>{renderText()}</Card.Body>
          </Card>
        </Row>
      </Container>
    </Page>
  );
}

const renderText = () => {
  return (
    <>
      <h1>
        <strong>
          <em>Privacy Policy</em>
        </strong>
      </h1>
      <p>&nbsp;</p>
      <p>
        <strong>Last updated: 13.03.2023</strong>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          The use of the Internet pages of the colab.wtf is possible without any
          indication of personal data; however, if a data subject wants to use
          special enterprise services via our website, processing of personal
          data could become necessary. If the processing of personal data is
          necessary and there is no statutory basis for such processing, we
          generally obtain consent from the data subject.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          The processing of personal data, such as the name, address, e-mail
          address, or telephone number of a data subject shall always be in line
          with the General Data Protection Regulation (GDPR), and in accordance
          with the country-specific data protection regulations applicable to
          the colab.wtf . By means of this data protection declaration, our
          enterprise would like to inform the general public of the nature,
          scope, and purpose of the personal data we collect, use and process.
          Furthermore, data subjects are informed, by means of this data
          protection declaration, of the rights to which they are entitled.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          As the controller, the colab.wtf has implemented numerous technical
          and organizational measures to ensure the most complete protection of
          personal data processed through this website. However, Internet-based
          data transmissions may in principle have security gaps, so absolute
          protection may not be guaranteed. For this reason, every data subject
          is free to transfer personal data to us via alternative means, e.g. by
          telephone.
        </span>
      </p>
      <h4>
        <strong>1. Definitions</strong>
      </h4>
      <p>
        <span style={{ fontWeight: "400" }}>
          The data protection declaration of the colab.wtf is based on the terms
          used by the European legislator for the adoption of the General Data
          Protection Regulation (GDPR). Our data protection declaration should
          be legible and understandable for the general public, as well as our
          customers and business partners. To ensure this, we would like to
          first explain the terminology used.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          In this data protection declaration, we use, inter alia, the following
          terms:
        </span>
      </p>
      <ul>
        <li style={{ fontWeight: "400" }}>
          <strong>a)</strong> <strong>Personal data</strong>
          <strong>
            <br />
          </strong>
          <span style={{ fontWeight: "400" }}>
            Personal data means any information relating to an identified or
            identifiable natural person (&ldquo;data subject&rdquo;). An
            identifiable natural person is one who can be identified, directly
            or indirectly, in particular by reference to an identifier such as a
            name, an identification number, location data, an online identifier
            or to one or more factors specific to the physical, physiological,
            genetic, mental, economic, cultural or social identity of that
            natural person.
          </span>
        </li>
        <li style={{ fontWeight: "400" }}>
          <strong>b) Data subject</strong>
          <strong>
            <br />
          </strong>
          <span style={{ fontWeight: "400" }}>
            Data subject is any identified or identifiable natural person, whose
            personal data is processed by the controller responsible for the
            processing.
          </span>
        </li>
        <li style={{ fontWeight: "400" }}>
          <strong>c)</strong> <strong>Processing</strong>
          <strong>
            <br />
          </strong>
          <span style={{ fontWeight: "400" }}>
            Processing is any operation or set of operations which is performed
            on personal data or on sets of personal data, whether or not by
            automated means, such as collection, recording, organisation,
            structuring, storage, adaptation or alteration, retrieval,
            consultation, use, disclosure by transmission, dissemination or
            otherwise making available, alignment or combination, restriction,
            erasure or destruction.
          </span>
        </li>
        <li style={{ fontWeight: "400" }}>
          <strong>d)</strong> <strong>Restriction of processing</strong>
          <strong>
            <br />
          </strong>
          <span style={{ fontWeight: "400" }}>
            Restriction of processing is the marking of stored personal data
            with the aim of limiting their processing in the future.
          </span>
        </li>
        <li style={{ fontWeight: "400" }}>
          <strong>e)</strong> <strong>Profiling</strong>
          <strong>
            <br />
          </strong>
          <span style={{ fontWeight: "400" }}>
            Profiling means any form of automated processing of personal data
            consisting of the use of personal data to evaluate certain personal
            aspects relating to a natural person, in particular to analyse or
            predict aspects concerning that natural person's performance at
            work, economic situation, health, personal preferences, interests,
            reliability, behaviour, location or movements.
          </span>
        </li>
        <li style={{ fontWeight: "400" }}>
          <strong>f) </strong> <strong>Pseudonymisation</strong>
          <strong>
            <br />
          </strong>
          <span style={{ fontWeight: "400" }}>
            Pseudonymisation is the processing of personal data in such a manner
            that the personal data can no longer be attributed to a specific
            data subject without the use of additional information, provided
            that such additional information is kept separately and is subject
            to technical and organisational measures to ensure that the personal
            data are not attributed to an identified or identifiable natural
            person.
          </span>
        </li>
        <li style={{ fontWeight: "400" }}>
          <strong>g)</strong>{" "}
          <strong>
            Controller or controller responsible for the processing
          </strong>
          <strong>
            <br />
          </strong>
          <span style={{ fontWeight: "400" }}>
            Controller or controller responsible for the processing is the
            natural or legal person, public authority, agency or other body
            which, alone or jointly with others, determines the purposes and
            means of the processing of personal data; where the purposes and
            means of such processing are determined by Union or Member State
            law, the controller or the specific criteria for its nomination may
            be provided for by Union or Member State law.
          </span>
        </li>
        <li style={{ fontWeight: "400" }}>
          <strong>h)</strong> <strong>Processor</strong>
          <strong>
            <br />
          </strong>
          <span style={{ fontWeight: "400" }}>
            Processor is a natural or legal person, public authority, agency or
            other body which processes personal data on behalf of the
            controller.
          </span>
        </li>
        <li style={{ fontWeight: "400" }}>
          <strong>i)&nbsp; </strong> <strong>Recipient</strong>
          <strong>
            <br />
          </strong>
          <span style={{ fontWeight: "400" }}>
            Recipient is a natural or legal person, public authority, agency or
            another body, to which the personal data are disclosed, whether a
            third party or not. However, public authorities which may receive
            personal data in the framework of a particular inquiry in accordance
            with Union or Member State law shall not be regarded as recipients;
            the processing of those data by those public authorities shall be in
            compliance with the applicable data protection rules according to
            the purposes of the processing.
          </span>
        </li>
        <li style={{ fontWeight: "400" }}>
          <strong>j)&nbsp; </strong> <strong>Third party</strong>
          <strong>
            <br />
          </strong>
          <span style={{ fontWeight: "400" }}>
            Third party is a natural or legal person, public authority, agency
            or body other than the data subject, controller, processor and
            persons who, under the direct authority of the controller or
            processor, are authorised to process personal data.
          </span>
        </li>
        <li style={{ fontWeight: "400" }}>
          <strong>k)</strong> <strong>Consent</strong>
          <strong>
            <br />
          </strong>
          <span style={{ fontWeight: "400" }}>
            Consent of the data subject is any freely given, specific, informed
            and unambiguous indication of the data subject's wishes by which he
            or she, by a statement or by a clear affirmative action, signifies
            agreement to the processing of personal data relating to him or her.
          </span>
        </li>
      </ul>
      <h4>
        <strong>2. Name and Address of the controller</strong>
      </h4>
      <p>
        <span style={{ fontWeight: "400" }}>
          Controller for the purposes of the General Data Protection Regulation
          (GDPR), other data protection laws applicable in Member states of the
          European Union and other provisions related to data protection is:
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>Matthias Krenz</span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>Bruchweg 4</span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>74252 Massenbachhausen</span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>Deutschland</span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>Phone: 03053676722</span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          Email: matthias.f.krenz@gmail.com
        </span>
      </p>
      <h4>
        <strong>3. Cookies</strong>
      </h4>
      <p>
        <span style={{ fontWeight: "400" }}>
          The Internet pages of the colab.wtf use cookies. Cookies are text
          files that are stored in a computer system via an Internet browser.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          Many Internet sites and servers use cookies. Many cookies contain a
          so-called cookie ID. A cookie ID is a unique identifier of the cookie.
          It consists of a character string through which Internet pages and
          servers can be assigned to the specific Internet browser in which the
          cookie was stored. This allows visited Internet sites and servers to
          differentiate the individual browser of the dats subject from other
          Internet browsers that contain other cookies. A specific Internet
          browser can be recognized and identified using the unique cookie ID.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          Through the use of cookies, the colab.wtf can provide the users of
          this website with more user-friendly services that would not be
          possible without the cookie setting.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          By means of a cookie, the information and offers on our website can be
          optimized with the user in mind. Cookies allow us, as previously
          mentioned, to recognize our website users. The purpose of this
          recognition is to make it easier for users to utilize our website. The
          website user that uses cookies, e.g. does not have to enter access
          data each time the website is accessed, because this is taken over by
          the website, and the cookie is thus stored on the user's computer
          system. Another example is the cookie of a shopping cart in an online
          shop. The online store remembers the articles that a customer has
          placed in the virtual shopping cart via a cookie.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          The data subject may, at any time, prevent the setting of cookies
          through our website by means of a corresponding setting of the
          Internet browser used, and may thus permanently deny the setting of
          cookies. Furthermore, already set cookies may be deleted at any time
          via an Internet browser or other software programs. This is possible
          in all popular Internet browsers. If the data subject deactivates the
          setting of cookies in the Internet browser used, not all functions of
          our website may be entirely usable.
        </span>
      </p>
      <h4>
        <strong>4. Collection of general data and information</strong>
      </h4>
      <p>
        <span style={{ fontWeight: "400" }}>
          The website of the colab.wtf collects a series of general data and
          information when a data subject or automated system calls up the
          website. This general data and information are stored in the server
          log files. Collected may be (1) the browser types and versions used,
          (2) the operating system used by the accessing system, (3) the website
          from which an accessing system reaches our website (so-called
          referrers), (4) the sub-websites, (5) the date and time of access to
          the Internet site, (6) an Internet protocol address (IP address), (7)
          the Internet service provider of the accessing system, and (8) any
          other similar data and information that may be used in the event of
          attacks on our information technology systems.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          When using these general data and information, the colab.wtf does not
          draw any conclusions about the data subject. Rather, this information
          is needed to (1) deliver the content of our website correctly, (2)
          optimize the content of our website as well as its advertisement, (3)
          ensure the long-term viability of our information technology systems
          and website technology, and (4) provide law enforcement authorities
          with the information necessary for criminal prosecution in case of a
          cyber-attack. Therefore, the colab.wtf analyzes anonymously collected
          data and information statistically, with the aim of increasing the
          data protection and data security of our enterprise, and to ensure an
          optimal level of protection for the personal data we process. The
          anonymous data of the server log files are stored separately from all
          personal data provided by a data subject.
        </span>
      </p>
      <h4>
        <strong>5. Routine erasure and blocking of personal data</strong>
      </h4>
      <p>
        <span style={{ fontWeight: "400" }}>
          The data controller shall process and store the personal data of the
          data subject only for the period necessary to achieve the purpose of
          storage, or as far as this is granted by the European legislator or
          other legislators in laws or regulations to which the controller is
          subject to.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          If the storage purpose is not applicable, or if a storage period
          prescribed by the European legislator or another competent legislator
          expires, the personal data are routinely blocked or erased in
          accordance with legal requirements.
        </span>
      </p>
      <h4>
        <strong>6. Rights of the data subject</strong>
      </h4>
      <ul>
        <li style={{ fontWeight: "400" }}>
          <strong>a) Right of confirmation</strong>
          <strong>
            <br />
          </strong>
          <span style={{ fontWeight: "400" }}>
            Each data subject shall have the right granted by the European
            legislator to obtain from the controller the confirmation as to
            whether or not personal data concerning him or her are being
            processed. If a data subject wishes to avail himself of this right
            of confirmation, he or she may, at any time, contact any employee of
            the controller.
          </span>
        </li>
        <li style={{ fontWeight: "400" }}>
          <strong>b) Right of access</strong>
          <strong>
            <br />
          </strong>
          <span style={{ fontWeight: "400" }}>
            Each data subject shall have the right granted by the European
            legislator to obtain from the controller free information about his
            or her personal data stored at any time and a copy of this
            information. Furthermore, the European directives and regulations
            grant the data subject access to the following information:
          </span>
        </li>
        <ul>
          <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
              the purposes of the processing;
            </span>
          </li>
          <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
              the categories of personal data concerned;
            </span>
          </li>
          <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
              the recipients or categories of recipients to whom the personal
              data have been or will be disclosed, in particular recipients in
              third countries or international organisations;
            </span>
          </li>
          <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
              where possible, the envisaged period for which the personal data
              will be stored, or, if not possible, the criteria used to
              determine that period;
            </span>
          </li>
          <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
              the existence of the right to request from the controller
              rectification or erasure of personal data, or restriction of
              processing of personal data concerning the data subject, or to
              object to such processing;
            </span>
          </li>
          <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
              the existence of the right to lodge a complaint with a supervisory
              authority;
            </span>
          </li>
          <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
              where the personal data are not collected from the data subject,
              any available information as to their source;
            </span>
          </li>
          <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
              the existence of automated decision-making, including profiling,
              referred to in Article 22(1) and (4) of the GDPR and, at least in
              those cases, meaningful information about the logic involved, as
              well as the significance and envisaged consequences of such
              processing for the data subject.
            </span>
          </li>
        </ul>
        <li style={{ fontWeight: "400" }}>
          <span style={{ fontWeight: "400" }}>
            Furthermore, the data subject shall have a right to obtain
            information as to whether personal data are transferred to a third
            country or to an international organisation. Where this is the case,
            the data subject shall have the right to be informed of the
            appropriate safeguards relating to the transfer.
          </span>
          <span style={{ fontWeight: "400" }}>
            <br />
          </span>
          <span style={{ fontWeight: "400" }}>
            If a data subject wishes to avail himself of this right of access,
            he or she may, at any time, contact any employee of the controller.
          </span>
        </li>
        <li style={{ fontWeight: "400" }}>
          <strong>c) Right to rectification</strong>
          <strong>
            <br />
          </strong>
          <span style={{ fontWeight: "400" }}>
            Each data subject shall have the right granted by the European
            legislator to obtain from the controller without undue delay the
            rectification of inaccurate personal data concerning him or her.
            Taking into account the purposes of the processing, the data subject
            shall have the right to have incomplete personal data completed,
            including by means of providing a supplementary statement.
          </span>
          <span style={{ fontWeight: "400" }}>
            <br />
          </span>
          <span style={{ fontWeight: "400" }}>
            If a data subject wishes to exercise this right to rectification, he
            or she may, at any time, contact any employee of the controller.
          </span>
        </li>
        <li style={{ fontWeight: "400" }}>
          <strong>d) Right to erasure (Right to be forgotten)</strong>
          <strong>
            <br />
          </strong>
          <span style={{ fontWeight: "400" }}>
            Each data subject shall have the right granted by the European
            legislator to obtain from the controller the erasure of personal
            data concerning him or her without undue delay, and the controller
            shall have the obligation to erase personal data without undue delay
            where one of the following grounds applies, as long as the
            processing is not necessary:
          </span>
        </li>
        <ul>
          <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
              The personal data are no longer necessary in relation to the
              purposes for which they were collected or otherwise processed.
            </span>
          </li>
          <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
              The data subject withdraws consent to which the processing is
              based according to point (a) of Article 6(1) of the GDPR, or point
              (a) of Article 9(2) of the GDPR, and where there is no other legal
              ground for the processing.
            </span>
          </li>
          <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
              The data subject objects to the processing pursuant to Article
              21(1) of the GDPR and there are no overriding legitimate grounds
              for the processing, or the data subject objects to the processing
              pursuant to Article 21(2) of the GDPR.
            </span>
          </li>
          <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
              The personal data have been unlawfully processed.
            </span>
          </li>
          <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
              The personal data must be erased for compliance with a legal
              obligation in Union or Member State law to which the controller is
              subject.
            </span>
          </li>
          <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
              The personal data have been collected in relation to the offer of
              information society services referred to in Article 8(1) of the
              GDPR.
            </span>
          </li>
        </ul>
        <li style={{ fontWeight: "400" }}>
          <span style={{ fontWeight: "400" }}>
            If one of the aforementioned reasons applies, and a data subject
            wishes to request the erasure of personal data stored by the
            colab.wtf , he or she may, at any time, contact any employee of the
            controller. An employee of colab.wtf shall promptly ensure that the
            erasure request is complied with immediately.
          </span>
          <span style={{ fontWeight: "400" }}>
            <br />
          </span>
          <span style={{ fontWeight: "400" }}>
            Where the controller has made personal data public and is obliged
            pursuant to Article 17(1) to erase the personal data, the
            controller, taking account of available technology and the cost of
            implementation, shall take reasonable steps, including technical
            measures, to inform other controllers processing the personal data
            that the data subject has requested erasure by such controllers of
            any links to, or copy or replication of, those personal data, as far
            as processing is not required. An employees of the colab.wtf will
            arrange the necessary measures in individual cases.
          </span>
        </li>
        <li style={{ fontWeight: "400" }}>
          <strong>e) Right of restriction of processing</strong>
          <strong>
            <br />
          </strong>
          <span style={{ fontWeight: "400" }}>
            Each data subject shall have the right granted by the European
            legislator to obtain from the controller restriction of processing
            where one of the following applies:
          </span>
        </li>
        <ul>
          <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
              The accuracy of the personal data is contested by the data
              subject, for a period enabling the controller to verify the
              accuracy of the personal data.
            </span>
          </li>
          <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
              The processing is unlawful and the data subject opposes the
              erasure of the personal data and requests instead the restriction
              of their use instead.
            </span>
          </li>
          <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
              The controller no longer needs the personal data for the purposes
              of the processing, but they are required by the data subject for
              the establishment, exercise or defence of legal claims.
            </span>
          </li>
          <li style={{ fontWeight: "400" }}>
            <span style={{ fontWeight: "400" }}>
              The data subject has objected to processing pursuant to Article
              21(1) of the GDPR pending the verification whether the legitimate
              grounds of the controller override those of the data subject.
            </span>
          </li>
        </ul>
        <li style={{ fontWeight: "400" }}>
          <span style={{ fontWeight: "400" }}>
            If one of the aforementioned conditions is met, and a data subject
            wishes to request the restriction of the processing of personal data
            stored by the colab.wtf , he or she may at any time contact any
            employee of the controller. The employee of the colab.wtf will
            arrange the restriction of the processing.
          </span>
        </li>
        <li style={{ fontWeight: "400" }}>
          <strong>f) Right to data portability</strong>
          <strong>
            <br />
          </strong>
          <span style={{ fontWeight: "400" }}>
            Each data subject shall have the right granted by the European
            legislator, to receive the personal data concerning him or her,
            which was provided to a controller, in a structured, commonly used
            and machine-readable format. He or she shall have the right to
            transmit those data to another controller without hindrance from the
            controller to which the personal data have been provided, as long as
            the processing is based on consent pursuant to point (a) of Article
            6(1) of the GDPR or point (a) of Article 9(2) of the GDPR, or on a
            contract pursuant to point (b) of Article 6(1) of the GDPR, and the
            processing is carried out by automated means, as long as the
            processing is not necessary for the performance of a task carried
            out in the public interest or in the exercise of official authority
            vested in the controller.
          </span>
          <span style={{ fontWeight: "400" }}>
            <br />
          </span>
          <span style={{ fontWeight: "400" }}>
            Furthermore, in exercising his or her right to data portability
            pursuant to Article 20(1) of the GDPR, the data subject shall have
            the right to have personal data transmitted directly from one
            controller to another, where technically feasible and when doing so
            does not adversely affect the rights and freedoms of others.
          </span>
          <span style={{ fontWeight: "400" }}>
            <br />
          </span>
          <span style={{ fontWeight: "400" }}>
            In order to assert the right to data portability, the data subject
            may at any time contact any employee of the colab.wtf .
          </span>
        </li>
        <li style={{ fontWeight: "400" }}>
          <strong>g) Right to object</strong>
          <strong>
            <br />
          </strong>
          <span style={{ fontWeight: "400" }}>
            Each data subject shall have the right granted by the European
            legislator to object, on grounds relating to his or her particular
            situation, at any time, to processing of personal data concerning
            him or her, which is based on point (e) or (f) of Article 6(1) of
            the GDPR. This also applies to profiling based on these provisions.
          </span>
          <span style={{ fontWeight: "400" }}>
            <br />
          </span>
          <span style={{ fontWeight: "400" }}>
            The colab.wtf shall no longer process the personal data in the event
            of the objection, unless we can demonstrate compelling legitimate
            grounds for the processing which override the interests, rights and
            freedoms of the data subject, or for the establishment, exercise or
            defence of legal claims.
          </span>
          <span style={{ fontWeight: "400" }}>
            <br />
          </span>
          <span style={{ fontWeight: "400" }}>
            If the colab.wtf processes personal data for direct marketing
            purposes, the data subject shall have the right to object at any
            time to processing of personal data concerning him or her for such
            marketing. This applies to profiling to the extent that it is
            related to such direct marketing. If the data subject objects to the
            colab.wtf to the processing for direct marketing purposes, the
            colab.wtf will no longer process the personal data for these
            purposes.
          </span>
          <span style={{ fontWeight: "400" }}>
            <br />
          </span>
          <span style={{ fontWeight: "400" }}>
            In addition, the data subject has the right, on grounds relating to
            his or her particular situation, to object to processing of personal
            data concerning him or her by the colab.wtf for scientific or
            historical research purposes, or for statistical purposes pursuant
            to Article 89(1) of the GDPR, unless the processing is necessary for
            the performance of a task carried out for reasons of public
            interest.
          </span>
          <span style={{ fontWeight: "400" }}>
            <br />
          </span>
          <span style={{ fontWeight: "400" }}>
            In order to exercise the right to object, the data subject may
            contact any employee of the colab.wtf . In addition, the data
            subject is free in the context of the use of information society
            services, and notwithstanding Directive 2002/58/EC, to use his or
            her right to object by automated means using technical
            specifications.
          </span>
        </li>
        <li style={{ fontWeight: "400" }}>
          <strong>
            h) Automated individual decision-making, including profiling
          </strong>
          <strong>
            <br />
          </strong>
          <span style={{ fontWeight: "400" }}>
            Each data subject shall have the right granted by the European
            legislator not to be subject to a decision based solely on automated
            processing, including profiling, which produces legal effects
            concerning him or her, or similarly significantly affects him or
            her, as long as the decision (1) is not is necessary for entering
            into, or the performance of, a contract between the data subject and
            a data controller, or (2) is not authorised by Union or Member State
            law to which the controller is subject and which also lays down
            suitable measures to safeguard the data subject's rights and
            freedoms and legitimate interests, or (3) is not based on the data
            subject's explicit consent.
          </span>
          <span style={{ fontWeight: "400" }}>
            <br />
          </span>
          <span style={{ fontWeight: "400" }}>
            If the decision (1) is necessary for entering into, or the
            performance of, a contract between the data subject and a data
            controller, or (2) it is based on the data subject's explicit
            consent, the colab.wtf shall implement suitable measures to
            safeguard the data subject's rights and freedoms and legitimate
            interests, at least the right to obtain human intervention on the
            part of the controller, to express his or her point of view and
            contest the decision.
          </span>
          <span style={{ fontWeight: "400" }}>
            <br />
          </span>
          <span style={{ fontWeight: "400" }}>
            If the data subject wishes to exercise the rights concerning
            automated individual decision-making, he or she may, at any time,
            contact any employee of the colab.wtf .
          </span>
        </li>
        <li style={{ fontWeight: "400" }}>
          <strong>i) Right to withdraw data protection consent</strong>
          <strong>
            <br />
          </strong>
          <span style={{ fontWeight: "400" }}>
            Each data subject shall have the right granted by the European
            legislator to withdraw his or her consent to processing of his or
            her personal data at any time.
          </span>
          <span style={{ fontWeight: "400" }}>
            <br />
          </span>
          <span style={{ fontWeight: "400" }}>
            If the data subject wishes to exercise the right to withdraw the
            consent, he or she may, at any time, contact any employee of the
            colab.wtf .
          </span>
        </li>
      </ul>
      <h4>
        <strong>
          7. Data protection provisions about the application and use of Google
          Analytics (with anonymization function)
        </strong>
      </h4>
      <p>
        <span style={{ fontWeight: "400" }}>
          On this website, the controller has integrated the component of Google
          Analytics (with the anonymizer function). Google Analytics is a web
          analytics service. Web analytics is the collection, gathering, and
          analysis of data about the behavior of visitors to websites. A web
          analysis service collects, inter alia, data about the website from
          which a person has come (the so-called referrer), which sub-pages were
          visited, or how often and for what duration a sub-page was viewed. Web
          analytics are mainly used for the optimization of a website and in
          order to carry out a cost-benefit analysis of Internet advertising.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          The operator of the Google Analytics component is Google Inc., 1600
          Amphitheatre Pkwy, Mountain View, CA 94043-1351, United States.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          For the web analytics through Google Analytics the controller uses the
          application "_gat. _anonymizeIp". By means of this application the IP
          address of the Internet connection of the data subject is abridged by
          Google and anonymised when accessing our websites from a Member State
          of the European Union or another Contracting State to the Agreement on
          the European Economic Area.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          The purpose of the Google Analytics component is to analyze the
          traffic on our website. Google uses the collected data and
          information, inter alia, to evaluate the use of our website and to
          provide online reports, which show the activities on our websites, and
          to provide other services concerning the use of our Internet site for
          us.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          Google Analytics places a cookie on the information technology system
          of the data subject. The definition of cookies is explained above.
          With the setting of the cookie, Google is enabled to analyze the use
          of our website. With each call-up to one of the individual pages of
          this Internet site, which is operated by the controller and into which
          a Google Analytics component was integrated, the Internet browser on
          the information technology system of the data subject will
          automatically submit data through the Google Analytics component for
          the purpose of online advertising and the settlement of commissions to
          Google. During the course of this technical procedure, the enterprise
          Google gains knowledge of personal information, such as the IP address
          of the data subject, which serves Google, inter alia, to understand
          the origin of visitors and clicks, and subsequently create commission
          settlements.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          The cookie is used to store personal information, such as the access
          time, the location from which the access was made, and the frequency
          of visits of our website by the data subject. With each visit to our
          Internet site, such personal data, including the IP address of the
          Internet access used by the data subject, will be transmitted to
          Google in the United States of America. These personal data are stored
          by Google in the United States of America. Google may pass these
          personal data collected through the technical procedure to third
          parties.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          The data subject may, as stated above, prevent the setting of cookies
          through our website at any time by means of a corresponding adjustment
          of the web browser used and thus permanently deny the setting of
          cookies. Such an adjustment to the Internet browser used would also
          prevent Google Analytics from setting a cookie on the information
          technology system of the data subject. In addition, cookies already in
          use by Google Analytics may be deleted at any time via a web browser
          or other software programs.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          In addition, the data subject has the possibility of objecting to a
          collection of data that are generated by Google Analytics, which is
          related to the use of this website, as well as the processing of this
          data by Google and the chance to preclude any such. For this purpose,
          the data subject must download a browser add-on under the link
          https://tools.google.com/dlpage/gaoptout and install it. This browser
          add-on tells Google Analytics through a JavaScript, that any data and
          information about the visits of Internet pages may not be transmitted
          to Google Analytics. The installation of the browser add-ons is
          considered an objection by Google. If the information technology
          system of the data subject is later deleted, formatted, or newly
          installed, then the data subject must reinstall the browser add-ons to
          disable Google Analytics. If the browser add-on was uninstalled by the
          data subject or any other person who is attributable to their sphere
          of competence, or is disabled, it is possible to execute the
          reinstallation or reactivation of the browser add-ons.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          As an alternative to the browser add-on, especially for browsers on
          mobile devices, you can prevent the collection by Google Analytics by
          clicking on{" "}
        </span>
        <em>
          <span style={{ fontWeight: "400" }}>this link</span>
        </em>
        <span style={{ fontWeight: "400" }}>
          . An opt-out cookie will be set which prevents the future collection
          of your data when you visit this website. The opt-out cookie is only
          valid in this browser and only for our website and is stored on your
          device. If you delete the cookies in this browser, you must set the
          opt-out cookie again.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          Further information and the applicable data protection provisions of
          Google may be retrieved under
          https://www.google.com/intl/en/policies/privacy/ and under
          http://www.google.com/analytics/terms/us.html. Google Analytics is
          further explained under the following Link
          https://www.google.com/analytics/.
        </span>
      </p>
      <h4>
        <strong>
          8. Data protection provisions about the application and use of
          DoubleClick / Google Adsense
        </strong>
      </h4>
      <p>
        <span style={{ fontWeight: "400" }}>
          On this website, the controller has integrated components of
          DoubleClick by Google. DoubleClick is a trademark of Google, under
          which predominantly special online marketing solutions are marketed to
          advertising agencies and publishers.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          The operating company of DoubleClick by Google is Google Inc., 1600
          Amphitheatre Pkwy, Mountain View, CA 94043-1351, UNITED STATES.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          DoubleClick by Google transmits data to the DoubleClick server with
          each impression, clicks, or other activity. Each of these data
          transfers triggers a cookie request to the data subject's browser. If
          the browser accepts this request, DoubleClick uses a cookie on the
          information technology system of the data subject. The definition of
          cookies is explained above. The purpose of the cookie is the
          optimization and display of advertising. The cookie is used, inter
          alia, to display and place user-relevant advertising as well as to
          create or improve reports on advertising campaigns. Furthermore, the
          cookie serves to avoid multiple display of the same advertisement.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          DoubleClick uses a cookie ID that is required to execute the technical
          process. For example, the cookie ID is required to display an
          advertisement in a browser. DoubleClick may also use the Cookie ID to
          record which advertisements have already been displayed in a browser
          in order to avoid duplications. It is also possible for DoubleClick to
          track conversions through the cookie ID. For instance, conversions are
          captured, when a user has previously been shown a DoubleClick
          advertising ad, and he or she subsequently makes a purchase on the
          advertiser's website using the same Internet browser.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          A cookie from DoubleClick does not contain any personal data. However,
          a DoubleClick cookie may contain additional campaign IDs. A campaign
          ID is used to identify campaigns that the user has already been in
          contact with.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          With each call-up to one of the individual pages of this website,
          which is operated by the controller and on which a DoubleClick
          component was integrated, the Internet browser on the information
          technology system of the data subject is automatically prompted by the
          respective DoubleClick component to send data for the purpose of
          online advertising and billing of commissions to Google. During the
          course of this technical procedure, Google gains knowledge of any data
          that Google may use to create commission calculations. Google may,
          inter alia, understand that the data subject has clicked on certain
          links on our website.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          The data subject may, as stated above, prevent the setting of cookies
          through our website at any time by means of a corresponding adjustment
          of the web browser used and thus permanently deny the setting of
          cookies. Such an adjustment to the Internet browser used would also
          prevent Google from setting a cookie on the information technology
          system of the data subject. In addition, cookies already in use by
          Google may be deleted at any time via a web browser or other software
          programs.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          Further information and the applicable data protection provisions of
          DoubleClick may be retrieved under DoubleClick by Google
          https://www.google.com/intl/en/policies/.
        </span>
      </p>
      <h4>
        <strong>9. Legal basis for the processing</strong>
      </h4>
      <p>
        <span style={{ fontWeight: "400" }}>
          Art. 6(1) lit. a GDPR serves as the legal basis for processing
          operations for which we obtain consent for a specific processing
          purpose. If the processing of personal data is necessary for the
          performance of a contract to which the data subject is party, as is
          the case, for example, when processing operations are necessary for
          the supply of goods or to provide any other service, the processing is
          based on Article 6(1) lit. b GDPR. The same applies to such processing
          operations which are necessary for carrying out pre-contractual
          measures, for example in the case of inquiries concerning our products
          or services. Is our company subject to a legal obligation by which
          processing of personal data is required, such as for the fulfillment
          of tax obligations, the processing is based on Art. 6(1) lit. c GDPR.
          In rare cases, the processing of personal data may be necessary to
          protect the vital interests of the data subject or of another natural
          person. This would be the case, for example, if a visitor were injured
          in our company and his name, age, health insurance data or other vital
          information would have to be passed on to a doctor, hospital or other
          third party. Then the processing would be based on Art. 6(1) lit. d
          GDPR. Finally, processing operations could be based on Article 6(1)
          lit. f GDPR. This legal basis is used for processing operations which
          are not covered by any of the abovementioned legal grounds, if
          processing is necessary for the purposes of the legitimate interests
          pursued by our company or by a third party, except where such
          interests are overridden by the interests or fundamental rights and
          freedoms of the data subject which require protection of personal
          data. Such processing operations are particularly permissible because
          they have been specifically mentioned by the European legislator. He
          considered that a legitimate interest could be assumed if the data
          subject is a client of the controller (Recital 47 Sentence 2 GDPR).
        </span>
      </p>
      <h4>
        <strong>
          10. The legitimate interests pursued by the controller or by a third
          party
        </strong>
      </h4>
      <p>
        <span style={{ fontWeight: "400" }}>
          Where the processing of personal data is based on Article 6(1) lit. f
          GDPR our legitimate interest is to carry out our business in favor of
          the well-being of all our employees and the shareholders.
        </span>
      </p>
      <h4>
        <strong>11. Period for which the personal data will be stored</strong>
      </h4>
      <p>
        <span style={{ fontWeight: "400" }}>
          The criteria used to determine the period of storage of personal data
          is the respective statutory retention period. After expiration of that
          period, the corresponding data is routinely deleted, as long as it is
          no longer necessary for the fulfillment of the contract or the
          initiation of a contract.
        </span>
      </p>
      <h4>
        <strong>
          12. Provision of personal data as statutory or contractual
          requirement; Requirement necessary to enter into a contract;
          Obligation of the data subject to provide the personal data; possible
          consequences of failure to provide such data
        </strong>
      </h4>
      <p>
        <span style={{ fontWeight: "400" }}>
          We clarify that the provision of personal data is partly required by
          law (e.g. tax regulations) or can also result from contractual
          provisions (e.g. information on the contractual partner). Sometimes it
          may be necessary to conclude a contract that the data subject provides
          us with personal data, which must subsequently be processed by us. The
          data subject is, for example, obliged to provide us with personal data
          when our company signs a contract with him or her. The non-provision
          of the personal data would have the consequence that the contract with
          the data subject could not be concluded. Before personal data is
          provided by the data subject, the data subject must contact any
          employee. The employee clarifies to the data subject whether the
          provision of the personal data is required by law or contract or is
          necessary for the conclusion of the contract, whether there is an
          obligation to provide the personal data and the consequences of
          non-provision of the personal data.
        </span>
      </p>
      <h4>
        <strong>13. Existence of automated decision-making</strong>
      </h4>
      <p>
        <span style={{ fontWeight: "400" }}>
          As a responsible company, we do not use automatic decision-making or
          profiling.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ol start="14">
        <li>
          <strong> Firebase Authentication</strong>
        </li>
      </ol>
      <p>
        <span style={{ fontWeight: "400" }}>
          We use Firebase Authentication to manage user authentication and
          authorization. When you create an account or log in to our website, we
          collect certain information from Firebase Authentication, such as your
          email address and name. This information is used to create and manage
          your account and to provide you with access to our services.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          Firebase Authentication is provided by Google LLC. For more
          information about how Google uses your data, please see their privacy
          policy: https://policies.google.com/privacy
        </span>
      </p>
      <ol start="15">
        <li>
          <strong> Firebase Firestore</strong>
        </li>
      </ol>
      <p>
        <span style={{ fontWeight: "400" }}>
          We use Firebase Firestore to store and manage user data, such as user
          profiles and preferences. When you use our services, we may collect
          and store certain information in Firebase Firestore, such as your
          name, email address, and user-generated content.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          Firebase Firestore is provided by Google LLC. For more information
          about how Google uses your data, please see their privacy policy:{" "}
        </span>
        <a href="https://policies.google.com/privacy">
          <span style={{ fontWeight: "400" }}>
            https://policies.google.com/privacy
          </span>
        </a>
      </p>
      <ol start="16">
        <li>
          <strong> YouTube Data API v3</strong>
        </li>
      </ol>
      <p>
        <span style={{ fontWeight: "400" }}>
          We use the YouTube Data API v3 to provide information about YouTube
          channels on our website. We may use the API to crawl information about
          your YouTube channel, such as the channel ID, number of subscribers
          and views. Please note that in order to access the YouTube Data API
          v3, we may need to verify your identity and authenticate your account
          with YouTube. This process may involve sharing certain information
          with YouTube, such as your YouTube username and channel ID.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: "400" }}>
          The YouTube Data API v3 is provided by Google LLC. For more
          information about how Google uses your data, please see their privacy
          policy:{" "}
        </span>
        <a href="https://policies.google.com/privacy">
          <span style={{ fontWeight: "400" }}>
            https://policies.google.com/privacy
          </span>
        </a>
      </p>
      <ol start="17">
        <li>
          <strong> Google API</strong>
        </li>
      </ol>
      <p>
        <span style={{ fontWeight: "400" }}>
          Colab.wtf&rsquo;s&nbsp; handling of Google user data obtained via
          Restricted and Sensitive Scopes is compliant with the Limited Use
          Policy. Colab.wtf&rsquo;s use and transfer of information received
          from Google APIs to any other app will adhere to{" "}
        </span>
        <a href="http://strip/?q=https%3A%2F%2Fdevelopers.google.com%2Fterms%2Fapi-services-user-data-policy%23additional_requirements_for_specific_api_scopes">
          <span style={{ fontWeight: "400" }}>
            Google API Services User Data Policy
          </span>
        </a>
        <span style={{ fontWeight: "400" }}>
          , including the Limited Use requirements.&rdquo;
        </span>
      </p>
      <ol start="18">
        <li>
          <strong> Leaflet</strong>
        </li>
      </ol>
      <p>
        <span style={{ fontWeight: "400" }}>
          We use Leaflet, an open-source mapping library, to display maps and
          location-based information on our website. When you use our mapping
          features, Leaflet may collect certain information about your location,
          such as your IP address.
        </span>
      </p>
      <p>
        <span style={{ fontWeight: "400" }}>
          Leaflet is provided by the Leaflet contributors. For more information
          about how Leaflet uses your data, please see their privacy policy:
          https://leafletjs.com/legal.html#privacy
        </span>
      </p>
      <ol start="19">
        <li>
          <strong> OpenStreetMap</strong>
        </li>
      </ol>
      <p>
        <span style={{ fontWeight: "400" }}>
          We use OpenStreetMap, an open-source mapping platform, to provide map
          data for our website. When you use our mapping features, OpenStreetMap
          may collect certain information about your location, such as your IP
          address.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontWeight: "400" }}>
          OpenStreetMap is provided by the OpenStreetMap Foundation. For more
          information about how OpenStreetMap uses your data, please see their
          privacy policy: https://wiki.osmfoundation.org/wiki/Privacy_Policy
        </span>
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </>
  );
};

export default PrivacyPolicy;
