import L, { LatLng } from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import React, { useEffect, useRef, useState } from "react";
import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet";
import SearchField from "./SearchField";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconAnchor: [11, 40],
});
L.Marker.prototype.options.icon = DefaultIcon;

const LocationPickerMap = ({
  children,
  initialLocation,
  onLocationChanged,
}) => {
  const [location, setLocation] = useState(null);
  const BERLIN = [52.520008, 13.404954];
  const defaultZoom = 13;
  const mapRef = useRef();

  useEffect(() => {
    const map = mapRef.current;
    if (initialLocation) {
      setLocation(initialLocation);
      map.flyTo(initialLocation, map.getZoom());
    } else {
      map.locate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onLocationChanged(location);
  }, [location, onLocationChanged]);

  function LocationMarker() {
    const map = useMapEvents({
      click(e) {
        setLocation(e.latlng);
      },
      locationfound(e) {
        setLocation(e.latlng);
        map.flyTo(e.latlng, map.getZoom());
      },
    });

    return location === null ? null : <Marker position={location}></Marker>;
  }

  return (
    <>
      <MapContainer
        style={{ height: "30vh", width: "100%" }}
        whenReady={(e) => {
          mapRef.current = e.target;
        }}
        className="map"
        center={BERLIN}
        zoom={defaultZoom}
        scrollWheelZoom={true}
        zoomControl={false}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {children}

        <SearchField
          onLoationChosen={(result) => {
            if (result?.location)
              setLocation(new LatLng(result.location.y, result.location.x));
          }}
        ></SearchField>
        <LocationMarker />
      </MapContainer>
    </>
  );
};

export default LocationPickerMap;
