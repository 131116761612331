export const SET_USER_COLLAB_DATA = "SET_USER_COLLAB_DATA";
export const CLEAR_USER_COLLAB_DATA = "CLEAR_USER_COLLAB_DATA";

export function setUserCollabDataAction(data) {
  return { type: SET_USER_COLLAB_DATA, payload: data };
}

export function clearUserCollabDataAction() {
  return { type: CLEAR_USER_COLLAB_DATA, payload: null };
}
