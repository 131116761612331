import React from "react";
import Background from "../components/Background";
import MainNavbar from "../components/MainNavbar";

function Page(props) {
  return (
    <Background>
      <header>
        <MainNavbar showBrand />
      </header>
      <main
        style={{
          minHeight: "100vh",
          backgroundColor: "transparent",
        }}
      >
        {props.children}
      </main>
    </Background>
  );
}

export default Page;
