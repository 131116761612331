import {
  CLEAR_SELECTED_CHANNEL_COLLAB_DATA,
  CLEAR_SELECTED_CHANNEL_YOUTUBE_DATA,
  SET_SELECTED_CHANNEL_COLLAB_DATA,
  SET_SELECTED_CHANNEL_YOUTUBE_DATA,
} from "../actions/collabActions";

const initialState = {
  selectedChannelData: {
    collab: null,
    youtube: null,
  },
};

const collabReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_CHANNEL_COLLAB_DATA:
      return {
        ...state,
        selectedChannelData: {
          ...state.selectedChannelData,
          collab: action.payload,
          youtube: state.selectedChannelData.youtube,
        },
      };
    case CLEAR_SELECTED_CHANNEL_COLLAB_DATA:
      return {
        ...state,
        selectedChannelData: {
          ...state.selectedChannelData,
          collab: null,
          youtube: state.selectedChannelData.youtube,
        },
      };
    case SET_SELECTED_CHANNEL_YOUTUBE_DATA:
      return {
        ...state,
        selectedChannelData: {
          ...state.selectedChannelData,
          youtube: action.payload,
          collab: state.selectedChannelData.collab,
        },
      };
    case CLEAR_SELECTED_CHANNEL_YOUTUBE_DATA:
      return {
        ...state,
        selectedChannelData: {
          ...state.selectedChannelData,
          youtube: null,
          collab: state.selectedChannelData.collab,
        },
      };
    default:
      return state;
  }
};

export default collabReducer;
