export const SET_SELECTED_CHANNEL_COLLAB_DATA =
  "SET_SELECTED_CHANNEL_COLLAB_DATA";
export const CLEAR_SELECTED_CHANNEL_COLLAB_DATA =
  "CLEAR_SELECTED_CHANNEL_COLLAB_DATA";

export const SET_SELECTED_CHANNEL_YOUTUBE_DATA =
  "SET_SELECTED_CHANNEL_YOUTUBE_DATA";
export const CLEAR_SELECTED_CHANNEL_YOUTUBE_DATA =
  "CLEAR_SELECTED_CHANNEL_YOUTUBE_DATA";

export function setSelectedChannelCollabDataAction(data) {
  return { type: SET_SELECTED_CHANNEL_COLLAB_DATA, payload: data };
}

export function clearSelectedChannelCollabDataAction() {
  return { type: CLEAR_SELECTED_CHANNEL_COLLAB_DATA, payload: null };
}

export function setSelectedChannelYoutubeDataAction(data) {
  return { type: SET_SELECTED_CHANNEL_YOUTUBE_DATA, payload: data };
}

export function clearSelectedChannelYoutubeDataAction() {
  return { type: CLEAR_SELECTED_CHANNEL_YOUTUBE_DATA, payload: null };
}
