// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  reauthenticateWithPopup,
  sendPasswordResetEmail,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import {
  addDoc,
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { useState } from "react";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBLHo4Q5071VlpMZdnRyUQKmx7ZAQM7FQI",
  authDomain: "collab-berlin.firebaseapp.com",
  projectId: "collab-berlin",
  storageBucket: "collab-berlin.appspot.com",
  messagingSenderId: "470882612285",
  appId: "1:470882612285:web:52bd0a466f9142143d6654",
  clientId:
    "470882612285-qg0mt4tmqc374igtl1tp2ljhv2d864t5.apps.googleusercontent.com",
  scopes: ["https://www.googleapis.com/auth/youtube.readonly"],
  disoveryDocs: [
    "https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest",
  ],
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const googleProvider = new GoogleAuthProvider();
googleProvider.addScope("https://www.googleapis.com/auth/youtube.readonly");

function useCredential() {
  const [credential, setCredential] = useState(null);
  return [credential, setCredential];
}

const logInWithAuth2 = async (id_token, access_token) => {
  const credential = GoogleAuthProvider.credential(id_token, access_token);
  return signInWithCredential(auth, credential);
};

const reauthenticateWithGoogle = (user) => {
  return reauthenticateWithPopup(user, googleProvider);
};

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  return signOut(auth);
};

export {
  auth,
  googleProvider,
  firebaseConfig,
  db,
  storage,
  signInWithGoogle,
  reauthenticateWithGoogle,
  logInWithAuth2,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  useCredential,
};
