const SubscriberCount = ({ count }) => {
  const formatSubscriberCount = (subscriberCount) => {
    if (!subscriberCount) return "NaN";
    if (subscriberCount > 1000000) return subscriberCount / 1000000 + "M";
    if (subscriberCount > 1000) return subscriberCount / 1000 + "k";
    return "<1k";
  };

  return "Subscribers: " + formatSubscriberCount(count);
};

export default SubscriberCount;
