import { GoogleAuthProvider } from "firebase/auth";
import { firebaseConfig } from "../api/firebase";
import {
  clearSelectedChannelCollabDataAction,
  clearSelectedChannelYoutubeDataAction,
  setSelectedChannelCollabDataAction,
  setSelectedChannelYoutubeDataAction,
} from "../redux/actions/collabActions";
import {
  clearUserCollabDataAction,
  setUserCollabDataAction,
} from "../redux/actions/userActions";
import {
  clearUserYoutubeDataAction,
  setUserYoutubeDataAction,
} from "../redux/actions/youtubeActions";
import { fetchCollabData } from "./colab-api";
import { fetchYoutubeChannelData } from "./google-api";

export const fetchUserData = (dispatch, googleSignInResult) => {
  const credential =
    GoogleAuthProvider.credentialFromResult(googleSignInResult);
  // TODO fetch with proper redux action states instead
  //dispose(fetchUserYoutubeData(firebaseConfig.apiKey, credential.accessToken))
  fetchYoutubeChannelData(
    firebaseConfig.apiKey,
    credential.accessToken,
    undefined
  )
    .then(
      (res) => {
        if (res.data && res.data.items && res.data.items[0]) {
          dispatch(setUserYoutubeDataAction(res.data.items[0]));
          const channelId = res.data.items[0].id;
          return Promise.resolve(channelId);
        } else {
          console.error(
            "fetchAllUserData.fetchYoutubeChannelData: No data items in response"
          );
          dispatch(setUserYoutubeDataAction(null));
          return Promise.reject(
            "fetchAllUserData.fetchYoutubeChannelData: No data items in response"
          );
        }
      },
      (err) => {
        return Promise.reject("fetchYoutubeChannelData: Execute error", err);
      }
    )
    .then((channelId) => {
      return fetchCollabData(channelId)
        .then((collabData) => {
          dispatch(setUserCollabDataAction(collabData));
        })
        .catch((err) => {
          console.error(err);
          dispatch(setUserCollabDataAction(null));
        });
    })
    .catch((err) => {
      console.error(err);
    });
};

export const clearUserData = (dispatch) => {
  dispatch(clearUserCollabDataAction());
  dispatch(clearUserYoutubeDataAction());
};

export const fetchCollabNoteData = (dispatch, channelId) => {
  return fetchYoutubeChannelData(firebaseConfig.apiKey, undefined, channelId)
    .then(
      (res) => {
        if (res.data && res.data.items && res.data.items[0]) {
          dispatch(setSelectedChannelYoutubeDataAction(res.data.items[0]));
          const channelId = res.data.items[0].id;
          return Promise.resolve(channelId);
        } else {
          dispatch(setSelectedChannelYoutubeDataAction(null));
          return Promise.reject(
            "fetchCollabNoteData.fetchYoutubeChannelData: No data items in response"
          );
        }
      },
      (err) => {
        return Promise.reject("fetchYoutubeChannelData: " + err);
      }
    )
    .then((channelId) => {
      return fetchCollabData(channelId)
        .then((collabData) => {
          dispatch(setSelectedChannelCollabDataAction(collabData));
          return Promise.resolve();
        })
        .catch((err) => {
          dispatch(setSelectedChannelCollabDataAction(null));
          return Promise.reject("fetchCollabData: " + err);
        });
    });
};

export const clearCollabNoteData = (dispatch) => {
  dispatch(clearSelectedChannelCollabDataAction());
  dispatch(clearSelectedChannelYoutubeDataAction());
};
