import L from "leaflet";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import logo from "../assets/marker-pin-person.png";
import LocateControl from "./LocateControl";
import "./Map.css";

const iconMarker = new L.Icon({
  iconUrl: logo,
  iconRetinaUrl: logo,
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [75, 75],
});

function Map(props) {
  const defaultLocation = [0, 0];
  const defaultZoom = 2;

  const onClusterClicked = (data) => {};

  const renderMarkers = () => {
    const renderObjects = [];
    Object.values(props.markerData).forEach((data) => {
      renderObjects.push(
        <Marker
          key={data.uid}
          center={[data.location._lat, data.location._long]}
          position={[data.location._lat, data.location._long]}
          icon={iconMarker}
          data={data.uid}
          eventHandlers={{
            click: (e) => {
              props.onMarkerClicked(e.target.options.data);
            },
          }}
        ></Marker>
      );
    });
    return renderObjects;
  };

  return (
    <MapContainer
      className="map"
      center={props.initialLocation ? props.initialLocation : defaultLocation}
      zoom={props.initialZoom ? props.initialZoom : defaultZoom}
      scrollWheelZoom={true}
      zoomControl={true}
    >
      <LocateControl
        options={{
          flyTo: true,
          strings: {
            title: "Your location",
          },
          locateOptions: {
            maxZoom: 12,
          },
        }}
      ></LocateControl>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {props.children}

      <MarkerClusterGroup
        onClick={(e) => onClusterClicked(e)}
        maxClusterRadius={150}
        spiderfyOnMaxZoom={true}
        iconCreateFunction={(cluster) => {
          var childCount = cluster.getChildCount();

          var c = " marker-cluster-";
          if (childCount < 10) {
            c += "small";
          } else if (childCount < 100) {
            c += "medium";
          } else {
            c += "large";
          }

          return new L.DivIcon({
            html: "<div><span>" + childCount + "</span></div>",
            className: "marker-cluster" + c,
            iconSize: new L.Point(40, 40),
          });
        }}
        polygonOptions={{
          fillColor: "#f8f9fa",
          color: "#3D43C7",
          weight: 5,
          opacity: 1,
          fillOpacity: 0.8,
        }}
        showCoverageOnHover={true}
      >
        {props.markerData && renderMarkers()}
      </MarkerClusterGroup>
    </MapContainer>
  );
}

export default Map;
