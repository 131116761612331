import React from "react";
import { Card } from "react-bootstrap";
import OverlayCardContainer from "../../components/OverlayCardContainer";

function UserDetailsNewTuber({ newTuberDetails, clearSelectedNewTuber }) {
  return (
    newTuberDetails && (
      <OverlayCardContainer className="overlay-container-new-tuber-note">
        <Card.Title>{newTuberDetails.name}</Card.Title>
        <Card.Text>
          <p>{newTuberDetails.message}</p>
        </Card.Text>
      </OverlayCardContainer>
    )
  );
}

export default UserDetailsNewTuber;
