import "@fontsource/inter";
import React from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Admin from "./pages/Admin";
import Collaborate from "./pages/collaborate/Collaborate";
import Home from "./pages/Home";
import Imprint from "./pages/Imprint";
import Licenses from "./pages/Licenses";
import NewTuber from "./pages/newtuber/NewTuber";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Purpose from "./pages/Purpose";
import TermsOfService from "./pages/TermsOfService";
import ThePerfectCollab from "./pages/ThePerfectCollab";

function App(bootstraped) {
  return bootstraped ? (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/purpose" element={<Purpose />} />
          <Route path="/the-perfect-collab" element={<ThePerfectCollab />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/newtuber" element={<NewTuber />} />
          <Route path="/collaborate" element={<Collaborate />} />
          <Route path="/imprint" element={<Imprint />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/licenses" element={<Licenses />} />
        </Routes>
      </Router>
    </div>
  ) : (
    <>Loading...</>
  );
}

export default App;
