import React, { useEffect, useState } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  addAdminRole,
  addYoutuber,
  deleteAdminRole,
  deleteYoutuber,
} from "../api/colab-api";
import { auth } from "../api/firebase";
import LocationPickerMap from "../components/LocationPickerMap";
import Page from "./Page";

function Admin() {
  const [user] = useAuthState(auth);
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  const [addAdminRoleEmail, setAddAdminRoleEmail] = useState("");
  const [deleteAdminRoleEmail, setDeleteAdminRoleEmail] = useState("");
  const [location, setLocation] = useState(null);
  const [channelIdAdd, setChannelIdAdd] = useState("");
  const [channelIdDelete, setChannelIdDelete] = useState("");
  const [validatedAddAdminRole, setValidatedAddAdminRole] = useState(false);
  const [validatedDeleteAdminRole, setValidatedDeleteAdminRole] =
    useState(false);
  const [validatedAddYoutuber, setValidatedAddYoutuber] = useState(false);
  const [validatedDeleteYoutuber, setValidatedDeleteYoutuber] = useState(false);

  useEffect(() => {
    if (!user) {
      setUserIsAdmin(false);
      return;
    }

    user
      .getIdTokenResult()
      .then((idTokenResult) => {
        if (idTokenResult.claims.admin) setUserIsAdmin(true);
        else setUserIsAdmin(false);
      })
      .catch((err) => {
        console.error(err);
        setUserIsAdmin(false);
      });
  }, [user]);

  const handleAddYoutuber = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      alert("invalid input");
      return;
    }
    setValidatedAddYoutuber(true);
    addYoutuber(user, channelIdAdd, location)
      .then((response) => {
        alert("Added Youtuber with channelId: " + channelIdAdd);
      })
      .catch((err) => {
        alert("Error: Couldn't add Youtuber with Channel Id: " + channelIdAdd);
        console.error(err);
      });
  };

  const handleDeleteYoutuber = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      alert("invalid input");
      return;
    }
    setValidatedDeleteYoutuber(true);
    deleteYoutuber(channelIdDelete)
      .then((response) => {
        alert("Deleted Youtuber with Channel Id: " + channelIdDelete);
      })
      .catch((err) => {
        alert(
          "Error: Couldn't delete Youtuber with Channel Id: " + channelIdDelete
        );
        console.error(err);
      });
  };

  const handleAddAdminRole = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      alert("invalid input");
      return;
    }
    setValidatedAddAdminRole(true);
    addAdminRole(user, addAdminRoleEmail)
      .then((response) => {
        alert("Success: " + response.data.message);
      })
      .catch((err) => {
        console.error(
          "Couldn't add admin role for user with email " +
            addAdminRoleEmail +
            ": " +
            err
        );
        alert(
          "ERROR: Couldn't add admin role for user with email " +
            addAdminRoleEmail
        );
      });
  };

  const handleDeleteAdminRole = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      alert("invalid input");
      return;
    }
    setValidatedDeleteAdminRole(true);
    deleteAdminRole(user, deleteAdminRoleEmail)
      .then((response) => {
        alert("Success: " + response.data.message);
      })
      .catch((err) => {
        console.error(
          "Couldn't delete admin role for user with email " +
            deleteAdminRoleEmail +
            ": " +
            err
        );
        alert(
          "ERROR: Couldn't delete admin role for user with email " +
            deleteAdminRoleEmail
        );
      });
  };

  const renderAdminComponent = () => {
    return (
      <div>
        <Card className="mb-3">
          <Card.Header>Add and update Youtuber</Card.Header>
          <Card.Body>
            <LocationPickerMap
              onLocationChanged={(location) => {
                setLocation(location);
              }}
            ></LocationPickerMap>
            <Form
              noValidate
              validated={validatedAddYoutuber}
              onSubmit={handleAddYoutuber}
            >
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Location</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Location"
                  disabled
                  readOnly
                  defaultValue={location}
                />
                <Form.Label>Channel Id</Form.Label>
                <Form.Control
                  style={{ background: "white" }}
                  required
                  type="text"
                  placeholder="Channel Id"
                  onChange={(e) => {
                    setChannelIdAdd(e.target.value);
                  }}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Add Youtuber
              </Button>
            </Form>
          </Card.Body>
        </Card>
        <Card className="mb-3">
          <Card.Header>Delete Youtuber</Card.Header>
          <Card.Body>
            <Form
              noValidate
              validated={validatedDeleteYoutuber}
              onSubmit={handleDeleteYoutuber}
            >
              <Form.Group className="mb-3" controlId="formChannelIdDelete">
                <Form.Label>Channel Id</Form.Label>
                <Form.Control
                  style={{ background: "white" }}
                  required
                  type="text"
                  placeholder="Channel Id"
                  onChange={(e) => {
                    setChannelIdDelete(e.target.value);
                  }}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Delete Youtuber
              </Button>
            </Form>
          </Card.Body>
        </Card>
        <Card className="mb-3">
          <Card.Header>Add admin role for email</Card.Header>
          <Card.Body>
            <Form
              noValidate
              validated={validatedAddAdminRole}
              onSubmit={handleAddAdminRole}
            >
              <Form.Group className="mb-3" controlId="formAddAdminRoleEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  style={{ background: "white" }}
                  type="email"
                  placeholder="Email"
                  required
                  defaultValue={addAdminRoleEmail}
                  onChange={(e) => {
                    setAddAdminRoleEmail(e.target.value);
                  }}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Add Admin Role
              </Button>
            </Form>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>Delete admin role for email</Card.Header>
          <Card.Body>
            <Form
              noValidate
              validated={validatedDeleteAdminRole}
              onSubmit={handleDeleteAdminRole}
            >
              <Form.Group className="mb-3" controlId="formDeleteAdminRoleEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  style={{ background: "white" }}
                  type="email"
                  placeholder="Email"
                  required
                  defaultValue={deleteAdminRoleEmail}
                  onChange={(e) => {
                    setDeleteAdminRoleEmail(e.target.value);
                  }}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Delete Admin Role
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    );
  };

  return (
    <Page>
      <div className="justify-content-center m-3">
        <Container fluid className="justify-content-center">
          {userIsAdmin ? (
            renderAdminComponent()
          ) : (
            <h1 style={{ color: "white" }}>
              You must be logged in as admin to see this page.
            </h1>
          )}
        </Container>
      </div>
    </Page>
  );
}

export default Admin;
