import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Button, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useAuthState } from "react-firebase-hooks/auth";
import { BsPlusSquare } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { auth, db, signInWithGoogle } from "../../api/firebase";
import {
  clearCollabNoteData,
  fetchCollabNoteData,
  fetchUserData,
} from "../../api/helper";
import MainNavbar from "../../components/MainNavbar";
import Map from "../../components/Map";
import OverlayCardContainer from "../../components/OverlayCardContainer";
import SearchField from "../../components/SearchField";
import CollabNote from "./CollabNote";
import "./Collaborate.scss";
import CreateCollabNote from "./CreateCollabNote";

function Collaborate() {
  const [user] = useAuthState(auth);
  const [showCollabForm, setShowCollabForm] = useState(false);

  const [showWelcomeMessage, setShowWelcomeMessage] = useState(true);
  const [youtubers, setYoutubers] = useState([]);
  const [filteredYoutubers, setFilteredYoutubers] = useState([]);
  const [subscribersFilter, setSubscribersFilter] = useState("all");
  const [selectedCollabChannelId, setSelectedCollabChannelId] = useState(null);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);

  function onMarkerClicked(id) {
    setSelectedCollabChannelId(id);
  }

  function onCloseCollabNoteClicked() {
    setSelectedCollabChannelId(null);
  }

  const onAddYourChannelClicked = () => {
    if (user) {
      setShowCollabForm(true);
    } else {
      signInWithGoogle().then((result) => {
        fetchUserData(dispatch, result);
        setShowCollabForm(true);
      });
    }
  };

  // load data when selected marker changes
  useEffect(() => {
    clearCollabNoteData(dispatch);
    if (!selectedCollabChannelId) {
      // no marker selected
      return;
    }
    fetchCollabNoteData(dispatch, selectedCollabChannelId).catch((err) =>
      console.error("Collaborate.fetchCollabNoteData: ", err)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCollabChannelId]);

  useEffect(() => {
    const docRef = doc(db, "public", "youtuber");
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        setYoutubers(
          Object.entries(docSnap.data()).map((val) => {
            const obj = val[1];
            obj["uid"] = val[0];
            return obj;
          })
        );
      } else {
        alert(
          "There was an error while trying to get data from our servers. Please try again later."
        );
        console.error("Couldn't fetch public/youtuber: No such document!");
      }
    });
  }, []);

  useEffect(() => {
    const filterYoutubers = () => {
      if (!youtubers) return [];
      return youtubers.filter((value) => {
        switch (subscribersFilter) {
          case "<10k":
            return value.subscriberCount < 10000;
          case "10k-100k":
            return (
              value.subscriberCount >= 10000 && value.subscriberCount < 100000
            );
          case "100k-1mio":
            return (
              value.subscriberCount >= 100000 && value.subscriberCount < 1000000
            );
          case "1mio+":
            return value.subscriberCount >= 1000000;
          default:
            return true;
        }
      });
    };
    setFilteredYoutubers(filterYoutubers());
  }, [youtubers, subscribersFilter]);

  const renderSubscriberFilter = () => {
    return (
      <div className="subscribers-filter">
        <Form className="subscribers-filter-form">
          <div key={`subscriber-radios`} className="mb-3">
            <Form.Check
              defaultChecked
              className="subscribers-filter-radio"
              inline
              label="All"
              name="subscribers-filter-group"
              type={"radio"}
              onClick={(e) => setSubscribersFilter("all")}
            />
            <Form.Check
              className="subscribers-filter-radio"
              inline
              label="<10k"
              name="subscribers-filter-group"
              type={"radio"}
              onClick={(e) => setSubscribersFilter("<10k")}
            />
            <Form.Check
              className="subscribers-filter-radio"
              inline
              label="10k-100k"
              name="subscribers-filter-group"
              type={"radio"}
              onClick={(e) => setSubscribersFilter("10k-100k")}
            />
            <Form.Check
              className="subscribers-filter-radio"
              inline
              label="100k-1mio"
              name="subscribers-filter-group"
              type={"radio"}
              onClick={(e) => setSubscribersFilter("100k-1mio")}
            />
            <Form.Check
              className="subscribers-filter-radio"
              inline
              label="1mio+"
              name="subscribers-filter-group"
              type={"radio"}
              onClick={(e) => setSubscribersFilter("1mio+")}
            />
          </div>
        </Form>
      </div>
    );
  };

  return (
    <div>
      <MainNavbar
        showBrand
        showYourCollabNote={true}
        onYourCollabNoteClicked={() => {
          onAddYourChannelClicked();
        }}
      ></MainNavbar>

      <Map markerData={filteredYoutubers} onMarkerClicked={onMarkerClicked}>
        <SearchField onLoationChosen={(result) => {}}></SearchField>
      </Map>

      {selectedCollabChannelId && (
        <CollabNote
          onCloseButtonClicked={onCloseCollabNoteClicked}
        ></CollabNote>
      )}
      {renderSubscriberFilter()}
      {showWelcomeMessage && (
        <OverlayCardContainer
          onCloseButtonClicked={() => {
            setShowWelcomeMessage(false);
          }}
          className="overlay-welcome-message text-center"
        >
          <Row className="justify-content-center">
            <span>
              Hey there! Welcome to the <b>CreatorMap</b>!
            </span>
            <span>
              Add your channel to easily get discovered by other creators for
              collaborations or by brands looking for location based creators.
            </span>
          </Row>
          <Row className="justify-content-center mt-4">
            <Button
              style={{ width: "auto" }}
              onClick={() => setShowWelcomeMessage(false)}
            >
              Ok
            </Button>
          </Row>
        </OverlayCardContainer>
      )}
      <Container fluid>
        <Row className="justify-content-center">
          {showCollabForm ? (
            <div className="overlay-bottom-center">
              <CreateCollabNote
                onCancel={() => {
                  setShowCollabForm(false);
                }}
                onSuccess={(data) => {
                  setShowCollabForm(false);
                  // TODO user && fetchUserYoutuberData();
                }}
                onError={(err) => {
                  console.error(err);
                  alert(
                    "There was an error saving your note, please try again later."
                  );
                }}
              />
            </div>
          ) : userData?.collab ? (
            <></>
          ) : (
            <Button
              variant="secondary"
              onClick={onAddYourChannelClicked}
              className="rounded-40 overlay-bottom-center"
            >
              <div>Add Your Channel</div>
              <BsPlusSquare />
            </Button>
          )}
        </Row>
      </Container>

      <footer className="Main-footer"></footer>
    </div>
  );
}

export default Collaborate;
