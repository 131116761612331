import axios from "axios";
function fetchYoutubeChannelData(
  api_key,
  accessToken = undefined,
  channelId = undefined
) {
  const API_URL = "https://youtube.googleapis.com/youtube/v3/channels";
  let url = API_URL + "?part=statistics&part=snippet";
  if (channelId) {
    url += "&id=" + channelId;
  } else {
    url += "&mine=true";
  }
  url += "&key=" + api_key;
  let config = {};
  if (accessToken) {
    config.headers = {
      Authorization: "Bearer " + accessToken,
      Accept: "application/json",
    };
  }
  return axios.get(url, config);
}

export { fetchYoutubeChannelData };
