import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Button, Container, Row } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import { BsPlusSquare } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../api/firebase";
import MainNavbar from "../../components/MainNavbar";
import Map from "../../components/Map";
import CreateNewTuber from "./CreateNewTuber";
import UserDetailsNewTuber from "./UserDetailsNewTuber";

function NewTuber() {
  const [user, loading, error] = useAuthState(auth);
  const [userNewTuber, setUserNewTuber] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showNewTuberForm, setShowNewTuberForm] = useState(false);
  const [newTubers, setNewTubers] = useState([]);
  const [selectedNewTuberId, setSelectedNewTuberId] = useState(null);
  const [selectedNewTuberDetails, setSelectedNewTuberDetails] = useState(null);
  const navigate = useNavigate();

  function onMarkerClicked(id) {
    setSelectedNewTuberId(id);
  }

  function clearSelectedNewTuber() {
    setSelectedNewTuberId(null);
  }

  const addNewTuberNote = () => {
    if (user) {
      setShowNewTuberForm(true);
    } else {
      navigate("/register");
    }
  };

  const fetchUserNewTuberData = async () => {
    const docRef = doc(db, "newTuber", user?.uid);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        console.log("NewTuber userNewTuber", docSnap.data());
        setUserNewTuber(docSnap.data());
      } else {
        console.log("Couldn't fetch user newtuber data: No such document!");
      }
    });
  };

  useEffect(() => {
    console.log(fetchUserNewTuberData);
    user && fetchUserNewTuberData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    setIsLoading(true);
    const docRef = doc(db, "public", "newTuber");
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        setNewTubers(docSnap.data());
        setIsLoading(false);
      } else {
        console.log("Couldn't fetch public/newTuber: No such document!");
        setIsLoading(false);
      }
    });
  }, []);

  // load NewTuber details when selected marker changes
  useEffect(() => {
    if (!selectedNewTuberId) {
      setSelectedNewTuberDetails(null);
      return;
    }

    const docRef = doc(db, "newTuber", selectedNewTuberId);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        setSelectedNewTuberDetails(docSnap.data());
      } else {
        // doc.data() will be undefined in this case
        console.log("Couldn't fetch NewTuber details: No such document!");
        setSelectedNewTuberDetails(null);
      }
    });
  }, [selectedNewTuberId]);

  return (
    <div>
      <MainNavbar showBrand />
      {loading || isLoading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <Map markers={newTubers} onMarkerClicked={onMarkerClicked} />
          <UserDetailsNewTuber
            newTuberDetails={selectedNewTuberDetails}
            clearSelectedNewTuber={clearSelectedNewTuber}
          />
          <Container fluid>
            <Row className="justify-content-center">
              {showNewTuberForm ? (
                <div className="overlay-bottom-center">
                  <CreateNewTuber
                    user={user}
                    userNewTuber={userNewTuber}
                    onCancel={() => {
                      setShowNewTuberForm(false);
                    }}
                    onSuccess={(data) => {
                      setShowNewTuberForm(false);
                      user && fetchUserNewTuberData();
                    }}
                    onError={() => {
                      console.error(error);
                      alert(
                        "There was an error saving your note, please try again later."
                      );
                    }}
                  />
                </div>
              ) : (
                <Button
                  variant="secondary"
                  onClick={addNewTuberNote}
                  className="rounded-40 overlay-bottom-center"
                >
                  <div>Add NewTuber note </div>
                  <BsPlusSquare />
                </Button>
              )}
            </Row>
          </Container>
        </div>
      )}

      <footer className="Main-footer"></footer>
    </div>
  );
}

export default NewTuber;
