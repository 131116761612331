import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Image,
  Nav,
  Navbar,
  Offcanvas,
  Row,
} from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { useAuthState } from "react-firebase-hooks/auth";
import GoogleButton from "react-google-button";
import { useDispatch } from "react-redux";
import {
  auth,
  logout as firebaseSignOut,
  signInWithGoogle as firebaseSignInWithGoogle,
} from "../api/firebase";
import { clearUserData, fetchUserData } from "../api/helper";
import developed_with_youtube from "../assets/developed_with_youtube.png";
import logo from "../assets/logo.png";
import "./MainNavbar.scss";
import NavItem from "./NavItem";

function MainNavbar({
  showBrand,
  showYourCollabNote,
  onYourCollabNoteClicked,
}) {
  const [user] = useAuthState(auth);
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const expand = "xs";
  const dispatch = useDispatch();

  const onSignInClicked = () => {
    firebaseSignInWithGoogle().then((result) => {
      fetchUserData(dispatch, result);
      setShowOffCanvas(false);
    });
  };

  const onSignOutClicked = () => {
    setShowOffCanvas(false);
    confirmAlert({
      title: "Logout",
      message: "Do you want to logout?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            firebaseSignOut().then(() => {
              clearUserData(dispatch);
            });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const onDeleteAccountClicked = () => {
    confirmAlert({
      title: "Delete account",
      message: "Do you really want delete your account?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            user.delete
              .then(() => {
                alert("Your account was deleted.");
                clearUserData(dispatch);
              })
              .catch((err) => {
                console.error(err);
                alert("There was an error.");
              });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <Navbar bg="primary" className="bg-opacity-75" expand={expand}>
      <Container fluid style={{ paddingTop: "0" }}>
        {showBrand && (
          <Navbar.Brand href="/">
            {showBrand && <Image className="navbar-brand-img" src={logo} />}
          </Navbar.Brand>
        )}
        <Navbar.Toggle
          className="navbar-button"
          onClick={() => setShowOffCanvas(true)}
          aria-controls={`offcanvasNavbar-expand-${expand}`}
        />
        <Navbar.Offcanvas
          show={showOffCanvas}
          id={`offcanvasNavbar-expand-${expand}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
          placement="end"
          onHide={() => setShowOffCanvas(false)}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
              <Navbar.Brand href="/">
                {showBrand && <Image className="navbar-brand-img" src={logo} />}
              </Navbar.Brand>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-start flex-grow-1 p-4">
              {user && showYourCollabNote && (
                <div>
                  <Row className="mt-4">
                    <Col>
                      <h4>Account</h4>
                    </Col>
                  </Row>
                  <NavItem
                    title="Your Collab Note"
                    onClick={() => {
                      setShowOffCanvas(false);
                      onYourCollabNoteClicked();
                    }}
                  ></NavItem>
                </div>
              )}
              <Row className="mt-4">
                <Col>
                  <h4>Navigate</h4>
                </Col>
              </Row>
              <NavItem title="Purpose" href="/purpose"></NavItem>
              {/*
              <NavItem
                title="The perfect collab"
                href="/the-perfect-collab"
              ></NavItem>
                  */}
              <NavItem title="CreatorMap" href="/collaborate"></NavItem>

              <Row className="mt-4">
                <Col>
                  <h4>About</h4>
                </Col>
              </Row>
              <NavItem
                title="Send Feedback"
                href="mailto:collabmapberlin@gmail.com"
              ></NavItem>
              <NavItem title="Privacy Policy" href="/privacy-policy"></NavItem>
              <NavItem
                title="Terms of Service"
                href="/terms-of-service"
              ></NavItem>
              <NavItem title="Imprint" href="/imprint"></NavItem>
              <div className="mt-4">
                {user ? (
                  <div>
                    <Row className="justify-content-center">
                      <Button
                        variant="primary"
                        className="width-auto"
                        onClick={onSignOutClicked}
                      >
                        Sign out
                      </Button>
                    </Row>
                    <Row className="justify-content-center">
                      <Button
                        onClick={onDeleteAccountClicked}
                        variant="secondary"
                        className="width-auto"
                      >
                        Delete Account
                      </Button>
                    </Row>
                  </div>
                ) : (
                  <div>
                    <Row className="justify-content-center">
                      <GoogleButton
                        style={{ padding: "0" }}
                        type="dark" // can be light or dark
                        onClick={onSignInClicked}
                      />
                    </Row>
                    <Row className="justify-content-center">
                      <a href="https://youtube.com">
                        <Image
                          style={{ width: "100%" }}
                          src={developed_with_youtube}
                        ></Image>
                      </a>
                    </Row>
                  </div>
                )}
              </div>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default MainNavbar;
