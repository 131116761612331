import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import logo_hands from "../assets/logo_hands.png";
import purpose_grow from "../assets/purpose/purpose_grow.jpg";
import purpose_network from "../assets/purpose/purpose_network.jpg";
import purpose_problem from "../assets/purpose/purpose_problem.jpg";
import purpose_radical_living from "../assets/purpose/purpose_radical_living.jpg";
import purpose_thomas from "../assets/purpose/purpose_thomas.png";
import purpose_why_collaborate from "../assets/purpose/purpose_why_collaborate.jpg";
import Page from "./Page";

function Purpose() {
  return (
    <Page>
      <Container
        fluid
        style={{
          backgroundColor: "transparent",
          color: "white",
          textAlign: "center",
          maxWidth: "1200px",
          font: "Inter",
        }}
      >
        {renderContent()}
      </Container>
    </Page>
  );
}

const renderContent = () => {
  return (
    <>
      <Row className="justify-content-center  mt-5">
        <Image style={{ width: "15vmin", minWidth: "5rem" }} src={logo_hands} />
      </Row>

      <h1 className="mt-5">Purpose</h1>
      <div className="justify-content-center mt-4">
        Our mission is to connect content creators across the globe and foster
        collaboration. We want to simplify the way creators connect and
        collaborate. Say goodbye to the hassle of searching for collaborators
        and hello to CoLab & TheCreatorMap.
      </div>

      <h2 className="mt-5">The problem</h2>
      <Row className="justify-content-center align-items-center mt-4">
        <Col className="align-items-center">
          Imagine you are a travel vlogger preparing for a trip across Asia.
          Until now, the only way to find other creators in a particular city
          was to scour Youtube using region-specific keywords, hoping to stumble
          upon someone living in that area. This approach is highly inefficient
          and time-consuming.
        </Col>
        <Col>
          <Image
            style={{
              width: "100%",
              paddingLeft: "4vw",
              paddingRight: "4vw",
            }}
            src={purpose_problem}
          />
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center">
        <Col>
          <Image
            style={{
              width: "100%",
              paddingLeft: "4vw",
              paddingRight: "4vw",
            }}
            src={purpose_radical_living}
          />
        </Col>
        <Col className="align-items-center">
          Or suppose you are a Creator who specializes in producing humorous
          skits, and you are eager to collaborate with others who share your
          passion. Perhaps you already have a few channels in mind that you
          would like to work with, but the challenge is figuring out where they
          are based.
        </Col>
      </Row>
      <div className="mt-4">
        Luckily, CoLab‘s{" "}
        <b>
          <a href="/collaborate">CreatorMap</a>
        </b>{" "}
        has the answer to all of these obstacles.
      </div>

      <h2 className="mt-5">Sponsors</h2>
      <span>
        It‘s not just creators who are facing difficulties in finding YouTubers
        based on their location. Many brands and companies are also on the
        lookout for creators in their respective regions. By adding your channel
        to CreatorMap, you increase your chances of being discovered by these
        brands and companies, making it much easier for you to connect with
        them.
      </span>

      <h1 className="mt-5">Why collaborate?</h1>
      <Image
        className="mt-4"
        style={{
          width: "100%",
          paddingLeft: "4vw",
          paddingRight: "4vw",
        }}
        src={purpose_why_collaborate}
      />

      <h2 className="mt-5">Grow your audience</h2>
      <div className="mt-4">
        Collaborations between YouTubers has been a powerful growth strategy for
        channels since the early days of the platform.
      </div>
      <Row className="justify-content-center align-items-center mt-5">
        <Col className="align-items-center">
          With just one collaboration, you can attract thousands of new
          subscribers in a single day, although the extent of this growth will
          depend on the size of the other channel.
          <br></br>
          On average, collaborations typically result in a subscriber growth
          rate of 1-10%, which can vary depending on the quality of the
          collaborative videos.
        </Col>
        <Col>
          <Image
            style={{
              width: "100%",
              paddingLeft: "4vw",
              paddingRight: "4vw",
            }}
            src={purpose_grow}
          />
        </Col>
      </Row>

      <h2 className="mt-5">Network & Have Fun</h2>
      <div className="mt-4">
        Being a YouTuber is a lonely business for most. While some channels may
        have a team of people working together, most creators work on their own.
      </div>
      <Row className="justify-content-center align-items-center mt-5">
        <Col>
          <Image
            style={{
              width: "100%",
              paddingLeft: "4vw",
              paddingRight: "4vw",
            }}
            src={purpose_network}
          />
        </Col>
        <Col className="align-items-center">
          That‘s why collaborating with other creators is crucial. By doing so,
          you can expand your network and have the opportunity to exchange ideas
          and create content together. Collaborating with other creatives is a
          great way to get inspired and stimulate your creativity.
        </Col>
      </Row>

      <h1 className="mt-5">The Team</h1>
      <Row className="justify-content-center align-items-center mt-5">
        <Col>
          <Image
            style={{
              width: "100%",
              paddingLeft: "4vw",
              paddingRight: "4vw",
            }}
            src={purpose_radical_living}
          />
        </Col>
        <Col className="align-items-center">
          <h1>
            <b>Matze</b>
          </h1>
          I‘ve been doing{" "}
          <a href="https://www.youtube.com/c/RadicalLiving">YouTube</a> now for
          more than 5 years now and the one thing that has always bugged me is
          how difficult it is to find other YouTubers to make collaborations
          with. Not because other YouTubers don‘t want to collaborate, but
          because there is no easy and quick way to find them, until now.
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center mt-5">
        <Col className="align-items-center">
          <h1>
            <b>Thomas</b>
          </h1>
          I like to <a href="https://thomasascioglu.com/world-trip/">travel</a>{" "}
          a lot and I love using/building location based apps that can help me,
          find the information I need quickly to simplify my life. Which is
          especally helpfull while being on the road. Sometimes I also make{" "}
          <a href="https://www.youtube.com/@thomasascioglu">YouTube</a> videos
          :)
          <br></br>
          At CoLab I'm responsible for the tech.
        </Col>
        <Col>
          <Image
            style={{
              width: "100%",
              paddingLeft: "4vw",
              paddingRight: "4vw",
            }}
            src={purpose_thomas}
          />
        </Col>
      </Row>
      <br></br>
      <br></br>
      <br></br>
    </>
  );
};

export default Purpose;
