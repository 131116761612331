import React from "react";

import background from "../assets/background.png";

function Background(props) {
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "auto",
          height: "100%",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundPosition: "center top",
        }}
      >
        {props.children}
      </div>
    </div>
  );
}

export default Background;
